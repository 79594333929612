import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import IndustryBlue from "../../assets/images/industryblue.png";
import CameraCountYellow from "../../assets/images/camerayellow.png";
import FeatureYellow from "../../assets/images/featureyellow.png";
import HardYellow from "../../assets/images/hardwareyellow.png";
import QuoteYellow from "../../assets/images/quoteyellow.png";
import Logo from "../../assets/logo/logowhite.png";
import Cat from "./industries.module.scss";
import Factory from "../../assets/images/factory.png";
import FoodAndBeverage from "../../assets/images/foodandbeverage.png";
import Retail from "../../assets/images/retail.png";
import { stateUpdate, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";

const Industries = () => {
  const navigate = useNavigate();
  const [food, setFood] = useState(false);
  const [retail, setRetail] = useState(false);
  const [manufacturing, setManufacturing] = useState(false);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  return (
    <div className={Cat.maincontainer}>
      <img className={Cat.logoimage} src={Logo} alt="logo" />
      <div className={Cat.progressholder}>
        <ProgressBar
          percent={1}
          filledBackground="#C7DBFF"
          unfilledBackground="#E4E4E4"
          height={5}
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={IndustryBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={CameraCountYellow}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={HardYellow}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={FeatureYellow}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={QuoteYellow}
                alt="stepone"
              />
            )}
          </Step>
        </ProgressBar>
      </div>
      <h3 className={Cat.screenheading}>Select Industry</h3>
      <div className={Cat.selectholder}>
        <div className={Cat.selectfield}>
          <div className={Cat.industrytop}>
            <h4 className={Cat.checklabel}>Food & Beverages</h4>
            <input
              type="radio"
              className={Cat.checkinput}
              checked={food}
              onChange={() => {
                setFood(food === true ? false : true);
                setRetail(false);
                setManufacturing(false);
              }}
            />
          </div>
          <img
            src={FoodAndBeverage}
            alt="foodandb"
            className={Cat.industryimage}
          />
        </div>
        <div className={Cat.selectfield}>
          <div className={Cat.industrytop}>
            <h4 className={Cat.checklabel}>Retail</h4>
            <input
              type="radio"
              className={Cat.checkinput}
              checked={retail}
              onChange={() => {
                setFood(false);
                setRetail(retail === true ? false : true);
                setManufacturing(false);
              }}
            />
          </div>
          <img src={Retail} alt="foodandb" className={Cat.industryimage} />
        </div>
        <div className={Cat.selectfield}>
          <div className={Cat.industrytop}>
            <h4 className={Cat.checklabel}>Manufacturing</h4>
            <input
              type="radio"
              className={Cat.checkinput}
              checked={manufacturing}
              onChange={() => {
                setFood(false);
                setRetail(false);
                setManufacturing(manufacturing === true ? false : true);
              }}
            />
          </div>
          <img src={Factory} alt="foodandb" className={Cat.industryimage} />
        </div>
      </div>
      <button
        className={
          food === true || retail === true || manufacturing === true
            ? Cat.nextbutton
            : Cat.nextbuttondisabled
        }
        onClick={() => {
          let userData = { ...userDetails };
          delete userData.cameracount;
          dispatch(stateUpdate(userData));
          navigate("/cameracount");
        }}
        disabled={
          food === true || retail === true || manufacturing === true
            ? false
            : true
        }
      >
        Next
      </button>
    </div>
  );
};

export default Industries;
