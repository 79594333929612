import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Feat from "./feature.module.scss";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import IndustryBlue from "../../assets/images/industryblue.png";
import CameraCountBlue from "../../assets/images/camerablue.png";
import FeatureBlue from "../../assets/images/featureblue.png";
import HardBlue from "../../assets/images/hardwareblue.png";
import QuoteYellow from "../../assets/images/quoteyellow.png";
import Logo from "../../assets/logo/logowhite.png";
import { stateUpdate, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";

const SelectFeature = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState([]);
  const [proall, setProall] = useState(false);
  const [outall, setOutall] = useState(false);
  const [groall, setGroall] = useState(false);
  const [genall, setGenall] = useState(false);
  const [cusall, setCusall] = useState(false);
  const [hygall, setHygall] = useState(false);
  const [featone, setFeatone] = useState(1);
  const [feattwo, setFeattwo] = useState(1);
  const [featthree, setFeatthree] = useState(1);
  const [featfour, setFeatfour] = useState(1);
  const [featfive, setFeatfive] = useState(1);
  const [featsix, setFeatsix] = useState(1);
  const [featseven, setFeatseven] = useState(1);
  const [feateight, setFeateight] = useState(1);
  const [featnine, setFeatnine] = useState(1);
  const [featten, setFeatten] = useState(1);
  const [featoneone, setFeatoneone] = useState(1);
  const [featonetwo, setFeatonetwo] = useState(1);
  const [featonethree, setFeatonethree] = useState(1);
  const [featonefour, setFeatonefour] = useState(1);
  const [featonefive, setFeatonefive] = useState(1);
  const [featonesix, setFeatonesix] = useState(1);
  const [featoneseven, setFeatoneseven] = useState(1);
  const [featoneeight, setFeatoneeight] = useState(1);
  const [featonenine, setFeatonenine] = useState(1);
  const [feattwozero, setFeattwozero] = useState(1);
  const [feattwoone, setFeattwoone] = useState(1);
  const [feattwotwo, setFeattwotwo] = useState(1);
  const [feattwothree, setFeatwothree] = useState(1);
  const [feattwofour, setFeattwofour] = useState(1);
  const [cost, setCost] = useState(0);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [expandPro, setExpandPro] = useState(false);
  const toggleAcordionPro = () => {
    setExpandPro((prev) => !prev);
  };
  const [expandOut, setExpandOut] = useState(false);
  const toggleAcordionOut = () => {
    setExpandOut((prev) => !prev);
  };
  const [expandGro, setExpandGro] = useState(false);
  const toggleAcordionGro = () => {
    setExpandGro((prev) => !prev);
  };
  const [expandGen, setExpandGen] = useState(false);
  const toggleAcordionGen = () => {
    setExpandGen((prev) => !prev);
  };
  const [expandCus, setExpandCus] = useState(false);
  const toggleAcordionCus = () => {
    setExpandCus((prev) => !prev);
  };
  const [expandHyg, setExpandHyg] = useState(false);
  const toggleAcordionHyg = () => {
    setExpandHyg((prev) => !prev);
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];

    let featurename = event.target.value.split("?");
    console.log(featurename);
    if (event.target.checked) {
      updatedList = [...checked, featurename[0]];
      if (
        updatedList.includes("Concierge Desk Unmanned") &&
        updatedList.includes("Idle Time Calculation of Butchers or Packers") &&
        updatedList.includes("Staff Attendance (App based)")
      ) {
        setProall(true);
      }
      if (
        updatedList.includes("Floor Mopping/Deep cleaning") &&
        updatedList.includes("Open Dustbin detected")
      ) {
        setOutall(true);
      }
      if (
        updatedList.includes(
          "Products packets not in direct contact of floor"
        ) &&
        updatedList.includes(
          "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)"
        ) &&
        updatedList.includes(
          "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme."
        ) &&
        updatedList.includes(
          "All the equipment is adequately sanitized before and after food preparation."
        ) &&
        updatedList.includes(
          "Veg/Non-veg Staff and zone Segregation (Based on Uniform)"
        )
      ) {
        setGroall(true);
      }
      if (
        updatedList.includes("No Smoking/Spitting") &&
        updatedList.includes("Talking/Browsing on the phone at work") &&
        updatedList.includes("Restricted Access (Uniform Based)") &&
        updatedList.includes("Violent Behaviour Detection") &&
        updatedList.includes(
          "Opening and closing time alerts based on Business Rules"
        )
      ) {
        setGenall(true);
      }
      if (
        updatedList.includes("Customer Wait Time Detection") &&
        updatedList.includes(
          "Hot and cold spaces in the outlet (Weekly Report)"
        ) &&
        updatedList.includes("Footfall Count") &&
        updatedList.includes(
          "Smilometer (at exit gate, on a Digital Screen)"
        ) &&
        updatedList.includes(
          "Demographics of walk-in customers : age range and gender"
        )
      ) {
        setCusall(true);
      }
      if (
        updatedList.includes("Chef Cap / Hairnet / Masks / Gloves detection") &&
        updatedList.includes("Uniform detection") &&
        updatedList.includes("Apron detection") &&
        updatedList.includes("Food packets lying in undesignated area")
      ) {
        setHygall(true);
      }
    } else {
      updatedList.splice(checked.indexOf(featurename[0]), 1);
      if (
        !updatedList.includes("Concierge Desk Unmanned") ||
        !updatedList.includes("Idle Time Calculation of Butchers or Packers") ||
        !updatedList.includes("Staff Attendance (App based)")
      ) {
        setProall(false);
      }
      if (
        !updatedList.includes("Floor Mopping/Deep cleaning") ||
        !updatedList.includes("Open Dustbin detected")
      ) {
        setOutall(false);
      }
      if (
        !updatedList.includes(
          "Products packets not in direct contact of floor"
        ) ||
        !updatedList.includes(
          "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)"
        ) ||
        !updatedList.includes(
          "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme."
        ) ||
        !updatedList.includes(
          "All the equipment is adequately sanitized before and after food preparation."
        ) ||
        !updatedList.includes(
          "Veg/Non-veg Staff and zone Segregation (Based on Uniform)"
        )
      ) {
        setGroall(false);
      }
      if (
        !updatedList.includes("No Smoking/Spitting") ||
        !updatedList.includes("Talking/Browsing on the phone at work") ||
        !updatedList.includes("Restricted Access (Uniform Based)") ||
        !updatedList.includes("Violent Behaviour Detection") ||
        !updatedList.includes(
          "Opening and closing time alerts based on Business Rules"
        )
      ) {
        setGenall(false);
      }
      if (
        !updatedList.includes("Customer Wait Time Detection") ||
        !updatedList.includes(
          "Hot and cold spaces in the outlet (Weekly Report)"
        ) ||
        !updatedList.includes("Footfall Count") ||
        !updatedList.includes(
          "Smilometer (at exit gate, on a Digital Screen)"
        ) ||
        !updatedList.includes(
          "Demographics of walk-in customers : age range and gender"
        )
      ) {
        setCusall(false);
      }
      if (
        !updatedList.includes(
          "Chef Cap / Hairnet / Masks / Gloves detection"
        ) ||
        !updatedList.includes("Uniform detection") ||
        !updatedList.includes("Apron detection") ||
        !updatedList.includes("Food packets lying in undesignated area")
      ) {
        setCusall(false);
      }
    }
    setChecked(updatedList);
  };

  const setCameraCount = () => {
    const options = [];

    for (let i = 0; i < userDetails.cameracount[0]; i++) {
      options.push(
        <option className={Feat.optioncameracount} value={i + 1}>
          {i + 1}
        </option>
      );
    }
    return options;
  };

  return (
    <div className={Feat.maincontainer}>
      <img className={Feat.logoimage} src={Logo} alt="logo" />
      <div className={Feat.progressholder}>
        <ProgressBar
          percent={75}
          filledBackground="#C7DBFF"
          unfilledBackground="#E4E4E4"
          height={5}
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={IndustryBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={CameraCountBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={HardBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={FeatureBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={QuoteYellow}
                alt="stepone"
              />
            )}
          </Step>
        </ProgressBar>
      </div>
      <h3 className={Feat.screenheading}>Select Features</h3>
      <h4 className={Feat.totalfeatures}>
        Total features selected: {checked.length}/24
      </h4>
      <div className={Feat.accordionholder}>
        <Accordion expanded={expandPro} className={Feat.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            IconButtonProps={{
              onClick: toggleAcordionPro,
            }}
          >
            <div className={Feat.accordionhead}>
              <h3 className={Feat.featurecategory}>Productivity</h3>
              <div className={Feat.selectholder}>
                <h4 className={Feat.featurename}>Select all</h4>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  checked={proall}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setProall(true);
                      let tempchecked = [...checked];
                      if (
                        tempchecked.includes("Concierge Desk Unmanned") ===
                        false
                      ) {
                        tempchecked.push("Concierge Desk Unmanned");
                      }
                      if (
                        tempchecked.includes(
                          "Idle Time Calculation of Butchers or Packers"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Idle Time Calculation of Butchers or Packers"
                        );
                      }
                      if (
                        tempchecked.includes("Staff Attendance (App based)") ===
                        false
                      ) {
                        tempchecked.push("Staff Attendance (App based)");
                      }
                      setChecked(tempchecked);
                    } else {
                      setProall(false);
                      let tempcheck = [...checked];
                      if (tempcheck.includes("Concierge Desk Unmanned")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Concierge Desk Unmanned") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Idle Time Calculation of Butchers or Packers"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Idle Time Calculation of Butchers or Packers"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (tempcheck.includes("Staff Attendance (App based)")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Staff Attendance (App based)") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      setChecked(tempcheck);
                    }
                  }}
                />
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>Concierge Desk Unmanned</h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Concierge Desk Unmanned?3000"}
                  checked={
                    checked.includes("Concierge Desk Unmanned") ? true : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp =
                        cost + parseInt(featurename[1]) * parseInt(featone);
                    } else {
                      if (cost === 0) {
                      } else {
                        temp =
                          cost - parseInt(featurename[1]) * parseInt(featone);
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Concierge Desk Unmanned") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatone(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Idle Time Calculation of Butchers or Packers
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Idle Time Calculation of Butchers or Packers?3000"}
                  checked={
                    checked.includes(
                      "Idle Time Calculation of Butchers or Packers"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp =
                        cost + parseInt(featurename[1]) * parseInt(feattwo);
                    } else {
                      if (cost === 0) {
                      } else {
                        temp =
                          cost - parseInt(featurename[1]) * parseInt(feattwo);
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Idle Time Calculation of Butchers or Packers"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeattwo(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Staff Attendance (App based)
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Staff Attendance (App based)?3000"}
                  checked={
                    checked.includes("Staff Attendance (App based)")
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp =
                        cost + parseInt(featurename[1]) * parseInt(featthree);
                    } else {
                      if (cost === 0) {
                      } else {
                        temp =
                          cost - parseInt(featurename[1]) * parseInt(featthree);
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Staff Attendance (App based)") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatthree(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandOut} className={Feat.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            IconButtonProps={{
              onClick: toggleAcordionOut,
            }}
          >
            <div className={Feat.accordionhead}>
              <h3 className={Feat.featurecategory}>Outlet Cleanliness</h3>
              <div className={Feat.selectholder}>
                <h4 className={Feat.featurename}>Select all</h4>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  checked={outall}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOutall(true);
                      let tempchecked = [...checked];
                      if (
                        tempchecked.includes("Floor Mopping/Deep cleaning") ===
                        false
                      ) {
                        tempchecked.push("Floor Mopping/Deep cleaning");
                      }
                      if (
                        tempchecked.includes("Open Dustbin detected") === false
                      ) {
                        tempchecked.push("Open Dustbin detected");
                      }
                      setChecked(tempchecked);
                    } else {
                      setOutall(false);
                      let tempcheck = [...checked];
                      if (tempcheck.includes("Floor Mopping/Deep cleaning")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Floor Mopping/Deep cleaning") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (tempcheck.includes("Open Dustbin detected")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Open Dustbin detected") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }

                      setChecked(tempcheck);
                    }
                  }}
                />
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Floor Mopping/Deep cleaning
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Floor Mopping/Deep cleaning?3000"}
                  checked={
                    checked.includes("Floor Mopping/Deep cleaning")
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp =
                        cost + parseInt(featurename[1]) * parseInt(featfour);
                    } else {
                      if (cost === 0) {
                      } else {
                        temp =
                          cost - parseInt(featurename[1]) * parseInt(featfour);
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Floor Mopping/Deep cleaning") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatfour(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>Open Dustbin detected</h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Open Dustbin detected?3000"}
                  checked={
                    checked.includes("Open Dustbin detected") ? true : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featfive;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featfive;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Open Dustbin detected") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatfive(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandGro} className={Feat.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            IconButtonProps={{
              onClick: toggleAcordionGro,
            }}
          >
            <div className={Feat.accordionhead}>
              <h3 className={Feat.featurecategory}>Grooming and Hygiene</h3>
              <div className={Feat.selectholder}>
                <h4 className={Feat.featurename}>Select all</h4>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  checked={groall}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setGroall(true);
                      let tempchecked = [...checked];
                      if (
                        tempchecked.includes(
                          "Products packets not in direct contact of floor"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Products packets not in direct contact of floor"
                        );
                      }
                      if (
                        tempchecked.includes(
                          "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)"
                        );
                      }
                      if (
                        tempchecked.includes(
                          "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme."
                        ) === false
                      ) {
                        tempchecked.push(
                          "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme."
                        );
                      }
                      if (
                        tempchecked.includes(
                          "All the equipment is adequately sanitized before and after food preparation."
                        ) === false
                      ) {
                        tempchecked.push(
                          "All the equipment is adequately sanitized before and after food preparation."
                        );
                      }
                      if (
                        tempchecked.includes(
                          "Veg/Non-veg Staff and zone Segregation (Based on Uniform)"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Veg/Non-veg Staff and zone Segregation (Based on Uniform)"
                        );
                      }
                      setChecked(tempchecked);
                    } else {
                      setGroall(false);
                      let tempcheck = [...checked];
                      if (
                        tempcheck.includes(
                          "Products packets not in direct contact of floor"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Products packets not in direct contact of floor"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme."
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme."
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "All the equipment is adequately sanitized before and after food preparation."
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "All the equipment is adequately sanitized before and after food preparation."
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Veg/Non-veg Staff and zone Segregation (Based on Uniform)"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Veg/Non-veg Staff and zone Segregation (Based on Uniform)"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }

                      setChecked(tempcheck);
                    }
                  }}
                />
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Products packets not in direct contact of floor
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Products packets not in direct contact of floor?3000"}
                  checked={
                    checked.includes(
                      "Products packets not in direct contact of floor"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featsix;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featsix;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Products packets not in direct contact of floor"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatsix(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Hand wash Process Detection (Using chemical, Paper towel and
                soft care gel)
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={
                    "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)?3000"
                  }
                  checked={
                    checked.includes(
                      "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featseven;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featseven;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Hand wash Process Detection (Using chemical, Paper towel and soft care gel)"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatseven(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Cleaning of equipment, food premises is done as per cleaning
                schedule & cleaning programme.
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={
                    "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme.?3000"
                  }
                  checked={
                    checked.includes(
                      "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme."
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * feateight;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * feateight;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme."
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeateight(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                All the equipment is adequately sanitized before and after food
                preparation.
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={
                    "All the equipment is adequately sanitized before and after food preparation.?3000"
                  }
                  checked={
                    checked.includes(
                      "All the equipment is adequately sanitized before and after food preparation."
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featnine;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featnine;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "All the equipment is adequately sanitized before and after food preparation."
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatnine(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Veg/Non-veg Staff and zone Segregation (Based on Uniform)
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={
                    "Veg/Non-veg Staff and zone Segregation (Based on Uniform)?17"
                  }
                  checked={
                    checked.includes(
                      "Veg/Non-veg Staff and zone Segregation (Based on Uniform)"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featten;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featten;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Veg/Non-veg Staff and zone Segregation (Based on Uniform)"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatten(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandGen} className={Feat.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            IconButtonProps={{
              onClick: toggleAcordionGen,
            }}
          >
            <div className={Feat.accordionhead}>
              <h3 className={Feat.featurecategory}>General SOP</h3>
              <div className={Feat.selectholder}>
                <h4 className={Feat.featurename}>Select all</h4>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  checked={genall}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setGenall(true);
                      let tempchecked = [...checked];
                      if (
                        tempchecked.includes("No Smoking/Spitting") === false
                      ) {
                        tempchecked.push("No Smoking/Spitting");
                      }
                      if (
                        tempchecked.includes(
                          "Talking/Browsing on the phone at work"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Talking/Browsing on the phone at work"
                        );
                      }
                      if (
                        tempchecked.includes(
                          "Restricted Access (Uniform Based)"
                        ) === false
                      ) {
                        tempchecked.push("Restricted Access (Uniform Based)");
                      }
                      if (
                        tempchecked.includes("Violent Behaviour Detection") ===
                        false
                      ) {
                        tempchecked.push("Violent Behaviour Detection");
                      }
                      if (
                        tempchecked.includes(
                          "Opening and closing time alerts based on Business Rules"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Opening and closing time alerts based on Business Rules"
                        );
                      }
                      setChecked(tempchecked);
                    } else {
                      setGenall(false);
                      let tempcheck = [...checked];
                      if (tempcheck.includes("No Smoking/Spitting")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "No Smoking/Spitting") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Talking/Browsing on the phone at work"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Talking/Browsing on the phone at work"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes("Restricted Access (Uniform Based)")
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] === "Restricted Access (Uniform Based)"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (tempcheck.includes("Violent Behaviour Detection")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Violent Behaviour Detection") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Opening and closing time alerts based on Business Rules"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Opening and closing time alerts based on Business Rules"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }

                      setChecked(tempcheck);
                    }
                  }}
                />
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>No Smoking/Spitting</h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"No Smoking/Spitting?300"}
                  checked={
                    checked.includes("No Smoking/Spitting") ? true : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featoneone;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featoneone;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("No Smoking/Spitting") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatoneone(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Talking/Browsing on the phone at work
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Talking/Browsing on the phone at work?17"}
                  checked={
                    checked.includes("Talking/Browsing on the phone at work")
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featonetwo;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featonetwo;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Talking/Browsing on the phone at work") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatonetwo(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Restricted Access (Uniform Based)
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Restricted Access (Uniform Based)?17"}
                  checked={
                    checked.includes("Restricted Access (Uniform Based)")
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featonethree;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featonethree;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Restricted Access (Uniform Based)") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatonethree(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Violent Behaviour Detection
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Violent Behaviour Detection?300"}
                  checked={
                    checked.includes("Violent Behaviour Detection")
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featonefour;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featonefour;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Violent Behaviour Detection") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatonefour(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Opening and closing time alerts based on Business Rules
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={
                    "Opening and closing time alerts based on Business Rules?17"
                  }
                  checked={
                    checked.includes(
                      "Opening and closing time alerts based on Business Rules"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featonefive;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featonefive;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Opening and closing time alerts based on Business Rules"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatonefive(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandCus} className={Feat.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            IconButtonProps={{
              onClick: toggleAcordionCus,
            }}
          >
            <div className={Feat.accordionhead}>
              <h3 className={Feat.featurecategory}>Customer Insight</h3>
              <div className={Feat.selectholder}>
                <h4 className={Feat.featurename}>Select all</h4>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  checked={cusall}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCusall(true);
                      let tempchecked = [...checked];
                      if (
                        tempchecked.includes("Customer Wait Time Detection") ===
                        false
                      ) {
                        tempchecked.push("Customer Wait Time Detection");
                      }
                      if (
                        tempchecked.includes(
                          "Hot and cold spaces in the outlet (Weekly Report)"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Hot and cold spaces in the outlet (Weekly Report)"
                        );
                      }
                      if (tempchecked.includes("Footfall Count") === false) {
                        tempchecked.push("Footfall Count");
                      }
                      if (
                        tempchecked.includes(
                          "Smilometer (at exit gate, on a Digital Screen)"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Smilometer (at exit gate, on a Digital Screen)"
                        );
                      }
                      if (
                        tempchecked.includes(
                          "Demographics of walk-in customers : age range and gender"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Demographics of walk-in customers : age range and gender"
                        );
                      }
                      setChecked(tempchecked);
                    } else {
                      setCusall(false);
                      let tempcheck = [...checked];
                      if (tempcheck.includes("Customer Wait Time Detection")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Customer Wait Time Detection") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Hot and cold spaces in the outlet (Weekly Report)"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Hot and cold spaces in the outlet (Weekly Report)"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (tempcheck.includes("Footfall Count")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Footfall Count") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Smilometer (at exit gate, on a Digital Screen)"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Smilometer (at exit gate, on a Digital Screen)"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Demographics of walk-in customers : age range and gender"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Demographics of walk-in customers : age range and gender"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }

                      setChecked(tempcheck);
                    }
                  }}
                />
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Customer Wait Time Detection
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Customer Wait Time Detection?17"}
                  checked={
                    checked.includes("Customer Wait Time Detection")
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featonesix;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featonesix;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Customer Wait Time Detection") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatonesix(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Hot and cold spaces in the outlet (Weekly Report)
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Hot and cold spaces in the outlet (Weekly Report)?17"}
                  checked={
                    checked.includes(
                      "Hot and cold spaces in the outlet (Weekly Report)"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featoneseven;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featoneseven;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Hot and cold spaces in the outlet (Weekly Report)"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatoneseven(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>Footfall Count</h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Footfall Count?17"}
                  checked={checked.includes("Footfall Count") ? true : false}
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featoneeight;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featoneeight;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Footfall Count") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatoneeight(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Smilometer (at exit gate, on a Digital Screen)
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Smilometer (at exit gate, on a Digital Screen)?300"}
                  checked={
                    checked.includes(
                      "Smilometer (at exit gate, on a Digital Screen)"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * featonenine;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * featonenine;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Smilometer (at exit gate, on a Digital Screen)"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatonenine(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Demographics of walk-in customers : age range and gender
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={
                    "Demographics of walk-in customers : age range and gender?300"
                  }
                  checked={
                    checked.includes(
                      "Demographics of walk-in customers : age range and gender"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * feattwozero;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * feattwozero;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Demographics of walk-in customers : age range and gender"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeattwozero(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandHyg} className={Feat.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            IconButtonProps={{
              onClick: toggleAcordionHyg,
            }}
          >
            <div className={Feat.accordionhead}>
              <h3 className={Feat.featurecategory}>Hygiene Maintain PPE</h3>
              <div className={Feat.selectholder}>
                <h4 className={Feat.featurename}>Select all</h4>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  checked={hygall}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setHygall(true);
                      let tempchecked = [...checked];
                      if (
                        tempchecked.includes(
                          "Chef Cap / Hairnet / Masks / Gloves detection"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Chef Cap / Hairnet / Masks / Gloves detection"
                        );
                      }
                      if (tempchecked.includes("Uniform detection") === false) {
                        tempchecked.push("Uniform detection");
                      }
                      if (tempchecked.includes("Apron detection") === false) {
                        tempchecked.push("Apron detection");
                      }
                      if (
                        tempchecked.includes(
                          "Food packets lying in undesignated area"
                        ) === false
                      ) {
                        tempchecked.push(
                          "Food packets lying in undesignated area"
                        );
                      }

                      setChecked(tempchecked);
                    } else {
                      setHygall(false);
                      let tempcheck = [...checked];
                      if (
                        tempcheck.includes(
                          "Chef Cap / Hairnet / Masks / Gloves detection"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Chef Cap / Hairnet / Masks / Gloves detection"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (tempcheck.includes("Uniform detection")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Uniform detection") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (tempcheck.includes("Apron detection")) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (tempcheck[i] === "Apron detection") {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }
                      if (
                        tempcheck.includes(
                          "Food packets lying in undesignated area"
                        )
                      ) {
                        for (let i = 0; i < tempcheck.length; i++) {
                          if (
                            tempcheck[i] ===
                            "Food packets lying in undesignated area"
                          ) {
                            tempcheck.splice(i, 1);
                          }
                        }
                      }

                      setChecked(tempcheck);
                    }
                  }}
                />
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Chef Cap / Hairnet / Masks / Gloves detection
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Chef Cap / Hairnet / Masks / Gloves detection?17"}
                  checked={
                    checked.includes(
                      "Chef Cap / Hairnet / Masks / Gloves detection"
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * feattwoone;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * feattwoone;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes(
                  "Chef Cap / Hairnet / Masks / Gloves detection"
                ) ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeattwoone(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>Uniform detection</h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Uniform detection?17"}
                  checked={checked.includes("Uniform detection") ? true : false}
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * feattwotwo;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * feattwotwo;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Uniform detection") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeattwotwo(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>Apron detection</h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Apron detection?17"}
                  checked={checked.includes("Apron detection") ? true : false}
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * feattwothree;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * feattwothree;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Apron detection") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeatwothree(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className={Feat.featureselectholder}>
              <h4 className={Feat.featurenameintra}>
                Food packets lying in undesignated area
              </h4>
              <div className={Feat.userdetailholder}>
                <input
                  type="checkbox"
                  className={Feat.featurecheck}
                  value={"Food packets lying in undesignated area?17"}
                  checked={
                    checked.includes("Food packets lying in undesignated area")
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleCheck(e);
                    let temp = 0;
                    let featurename = e.target.value.split("?");
                    if (e.target.checked) {
                      temp = cost + parseInt(featurename[1]) * feattwofour;
                    } else {
                      if (cost === 0) {
                      } else {
                        temp = cost - parseInt(featurename[1]) * feattwofour;
                      }
                    }
                    setCost(temp);
                    console.log("cost", cost);
                  }}
                />
                {checked.includes("Food packets lying in undesignated area") ? (
                  <select
                    className={Feat.selectcameracount}
                    onChange={(e) => setFeattwofour(e.target.value)}
                  >
                    <option
                      className={Feat.optioncameracount}
                      disabled
                      selected
                    >
                      Select Camera Count
                    </option>
                    {setCameraCount()}
                  </select>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <button
        className={
          checked.length !== 0 ? Feat.nextbutton : Feat.nextbuttondisabled
        }
        onClick={() => {
          let userData = { ...userDetails };
          userData.featurecost = cost;
          dispatch(stateUpdate(userData));
          navigate("/quote");
        }}
      >
        Next
      </button>
    </div>
  );
};

export default SelectFeature;
