import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Range, getTrackBackground } from "react-range";
import { stateUpdate, authData } from "../../redux/auth/authSlice";

const STEP = 1;
const MIN = 0;
const MAX = 50;

const RangeSlider = () => {
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [range, setRange] = useState({ values: [0] });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "2em",
      }}
    >
      <Range
        values={range.values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => {
          setRange({ values });
          let userData = { ...userDetails };
          userData.cameracount = values;
          dispatch(stateUpdate(userData));
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: range.values,
                  colors: ["#0043BF", "#ccc"],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "42px",
              width: "22px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
      <output
        style={{ marginTop: "30px", color: "white", fontSize: "1.5vw" }}
        id="output"
      >
        {range.values[0].toFixed(0)} Cameras
      </output>
    </div>
  );
};

export default RangeSlider;
