import React from "react";
import { useNavigate } from "react-router-dom";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import IndustryBlue from "../../assets/images/industryblue.png";
import CameraCountBlue from "../../assets/images/camerablue.png";
import FeatureYellow from "../../assets/images/featureyellow.png";
import HardYellow from "../../assets/images/hardwareyellow.png";
import QuoteYellow from "../../assets/images/quoteyellow.png";
import Logo from "../../assets/logo/logowhite.png";
import Cam from "./cameracount.module.scss";
import RangeSlider from "./rangeslider";
import { authData } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const CameraCount = () => {
  const navigate = useNavigate();
  let userDetails = useSelector(authData);
  console.log("userDetails", userDetails);

  return (
    <div className={Cam.maincontainer}>
      <img className={Cam.logoimage} src={Logo} alt="logo" />
      <div className={Cam.progressholder}>
        <ProgressBar
          percent={25}
          filledBackground="#C7DBFF"
          unfilledBackground="#E4E4E4"
          height={5}
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={IndustryBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={CameraCountBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={HardYellow}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={FeatureYellow}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={QuoteYellow}
                alt="stepone"
              />
            )}
          </Step>
        </ProgressBar>
      </div>
      <h3 className={Cam.screenheading}>Select number of cameras</h3>
      <div className={Cam.rangeslider}>
        <h2 className={Cam.rangenumber}>0</h2>
        <div className={Cam.ranger}>
          <RangeSlider />
        </div>
        <h2 className={Cam.rangenumber}>50+</h2>
      </div>
      <button
        className={
          userDetails.cameracount !== undefined
            ? userDetails.cameracount[0] !== 0
              ? Cam.nextbutton
              : Cam.nextbuttondisabled
            : Cam.nextbuttondisabled
        }
        onClick={() => navigate("/selecthardware")}
      >
        Next
      </button>
    </div>
  );
};

export default CameraCount;
