import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Pre from "./prelogin.module.scss";
import Logo from "../../assets/logo/logowhite.png";
import { stateUpdate } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import PreLogin from "../../assets/images/loginimage.png";
import jwt_decode from "jwt-decode";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import axios from "axios";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const Prelogin = () => {
  const navigate = useNavigate();
  let userDetails = {};
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();

  const saveTokenAndNavigate = (token) => {
    swal({
      title: "Success!",
      text: "Logged in",
      icon: "success",
      buttons: false,
    });
    let userData = { ...userDetails };
    userData.authtoken = token;
    let alldata = jwt_decode(token);
    userData.username = alldata.name;
    userData.email = alldata.email;
    dispatch(stateUpdate(userData));
    setSpin(false);
    navigate("/selectindustries");
  };

  // Function to validate login form
  const validateForm = () => {
    let formIsValid = true;
    setEmailErr("");
    setPassErr("");

    if (email === "" || email === null || email.trim() === "") {
      formIsValid = false;
      setEmailErr("Please enter your email");
      return;
    }
    if (password === "" || password === null || password.trim() === "") {
      formIsValid = false;
      setPassErr("Please enter your password");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      loginUser();

      return;
    }
  };

  //API to send user details
  const loginUser = () => {
    setSpin(true);
    var data = qs.stringify({
      email: email.toString(),
      password: password.toString(),
    });

    var config = {
      method: "POST",
      url: "https://api.oc3.oureye.ai/auth/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log(result.data["access_token"]);
        saveTokenAndNavigate(result.data["access_token"]);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Incorrect Username or Password",
          icon: "error",
          buttons: false,
        });
      });
  };

  return (
    <div className={Pre.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Pre.leftlogin}>
        <img src={Logo} className={Pre.logo} alt="logo" />
        <h4 className={Pre.heading}>Login</h4>
        <div className={Pre.inputholder}>
          <h4 className={Pre.inputlabel}>Email Address:</h4>
          <input
            type="text"
            className={Pre.input}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <h4 className={Pre.errormessage}>{emailErr}</h4>
        <div className={Pre.inputholder}>
          <h4 className={Pre.inputlabel}>Password:</h4>
          <input
            type="password"
            className={Pre.input}
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <h4 className={Pre.errormessage}>{passErr}</h4>
        <button className={Pre.nextbutton} onClick={() => validateForm()}>
          Login
        </button>
      </div>
      <div className={Pre.rightimage}>
        <img src={PreLogin} alt="loginimage" className={Pre.preloginimage} />
      </div>
    </div>
  );
};

export default Prelogin;
