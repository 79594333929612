import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.module.scss";
import { authData } from "./redux/auth/authSlice";
import Industries from "./screens/industries/industries";
import CameraCount from "./screens/cameracount/cameracount";
import Hardware from "./screens/hardware/hardware";
import SelectFeature from "./screens/features/feature";
import Prelogin from "./screens/prelogin/prelogin";
import Quote from "./screens/quote/quote";

function App() {
  let userDetails = useSelector(authData);

  return (
    <Router>
      {userDetails === 0 ? (
        <Routes>
          <Route exact path="/" element={<Prelogin />} />
          <Route exact path="*" element={<Prelogin />} />
        </Routes>
      ) : (
        <Routes>
          <Route exact path="/" element={<Industries />} />
          <Route exact path="*" element={<Industries />} />
          <Route exact path="/selectindustries" element={<Industries />} />
          <Route exact path="/cameracount" element={<CameraCount />} />
          <Route exact path="/selecthardware" element={<Hardware />} />
          <Route exact path="/selectfeatures" element={<SelectFeature />} />
          <Route exact path="/quote" element={<Quote />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
