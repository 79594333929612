import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo/logowhite.png";
import Qu from "./quote.module.scss";
import { ProgressBar, Step } from "react-step-progress-bar";
import IndustryBlue from "../../assets/images/industryblue.png";
import CameraCountBlue from "../../assets/images/camerablue.png";
import FeatureBlue from "../../assets/images/featureblue.png";
import HardBlue from "../../assets/images/hardwareblue.png";
import QuoteBlue from "../../assets/images/quoteblue.png";
import { authData } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const Quote = () => {
  let userDetails = useSelector(authData);
  const [monthlycost, setMonthlyCost] = useState(0);
  const [annualCost, setAnnualCost] = useState(0);

  useEffect(() => {
    showQuote(userDetails.cameracount[0], userDetails.featurecost);
    //eslint-disable-next-line
  }, []);

  const showQuote = (cameracount, featurecost) => {
    let monthlycost = userDetails.cameracount[0] * 3000;
    let annualcost = monthlycost * 12;
    let discountprice = monthlycost * 12 * 0.1;
    setMonthlyCost(monthlycost.toFixed(0));
    setAnnualCost((annualcost - discountprice).toFixed(0));
  };

  return (
    <div className={Qu.maincontainer}>
      <img className={Qu.logoimage} src={Logo} alt="logo" />
      <div className={Qu.progressholder}>
        <ProgressBar
          percent={100}
          filledBackground="#C7DBFF"
          unfilledBackground="#E4E4E4"
          height={5}
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={IndustryBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={CameraCountBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={HardBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={FeatureBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={QuoteBlue}
                alt="stepone"
              />
            )}
          </Step>
        </ProgressBar>
      </div>
      <h3 className={Qu.screenheading}>Quote</h3>
      <div className={Qu.quoteholder}>
        <div className={Qu.leftholder}>
          <h4 className={Qu.headingleft}>Monthly</h4>
          <br />
          <div className={Qu.innerquoteholder}>
            <div className={Qu.intraquoteholder}>
              <h5 className={Qu.priceleft}>₹{monthlycost}/-</h5>
              <h6 className={Qu.smalltextleft}>monthly maintenance cost</h6>
            </div>
            <h4 className={Qu.priceleft}>+</h4>
            <div className={Qu.intraquoteholder}>
              <h5 className={Qu.priceleft}>₹{userDetails.hardwarecost}/-</h5>
              <h6 className={Qu.smalltextleft}>one time hardware cost</h6>
            </div>
          </div>
        </div>
        <div className={Qu.rightholder}>
          <h4 className={Qu.headingright}>Annually</h4>

          <h6 className={Qu.smalltextright}>(10% off on monthly bill)</h6>
          <br />
          <div className={Qu.innerquoteholder}>
            <div className={Qu.intraquoteholder}>
              <h5 className={Qu.priceright}>₹{annualCost}/-</h5>
              <h6 className={Qu.smalltextright}>annual maintenance cost</h6>
            </div>
            <h4 className={Qu.priceright}>+</h4>
            <div className={Qu.intraquoteholder}>
              <h5 className={Qu.priceright}>₹{userDetails.hardwarecost}/-</h5>
              <h6 className={Qu.smalltextright}>one time hardware cost</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
