import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Hard from "./hardware.module.scss";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import IndustryBlue from "../../assets/images/industryblue.png";
import CameraCountBlue from "../../assets/images/camerablue.png";
import FeatureYellow from "../../assets/images/featureyellow.png";
import HardBlue from "../../assets/images/hardwareblue.png";
import QuoteYellow from "../../assets/images/quoteyellow.png";
import Logo from "../../assets/logo/logowhite.png";
import { stateUpdate, authData } from "../../redux/auth/authSlice";

const Hardware = () => {
  const navigate = useNavigate();
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [minProcessor, setMinProcessor] = useState("");
  const [maxProcessor, setMaxProcessor] = useState("");
  const [minRam, setMinRam] = useState("");
  const [maxRam, setMaxRam] = useState("");
  const [hardwarecost, setHardwarecost] = useState(0);
  const [minHardwarecost, setMinhardwarecost] = useState(0);
  const [minCamCount, setMinCamCount] = useState("");
  const [maxCamCount, setMaxCamCount] = useState("");
  const [minHardware, setMinHardware] = useState(false);
  const [recHardware, setRecHardware] = useState(true);

  useEffect(() => {
    selectHardware(userDetails.cameracount[0]);
    //eslint-disable-next-line
  }, []);

  const selectHardware = (count) => {
    if (count < 10) {
      setMinProcessor("i3");
      setMinRam("8 GB");
      setMinCamCount("10");
      setMinhardwarecost(55000);
      setMaxProcessor("i3");
      setMaxRam("16 GB");
      setMaxCamCount("15");
      setHardwarecost(55000);
    } else if (count < 15) {
      setMinProcessor("i3");
      setMinRam("16 GB");
      setMinCamCount("15");
      setMinhardwarecost(55000);
      setMaxProcessor("i5");
      setMaxRam("8 GB");
      setMaxCamCount("18");
      setHardwarecost(75000);
    } else if (count < 18) {
      setMinProcessor("i5");
      setMinRam("8 GB");
      setMinCamCount("18");
      setMinhardwarecost(75000);
      setMaxProcessor("i5");
      setMaxRam("16GB");
      setMaxCamCount("25");
      setHardwarecost(75000);
    } else if (count < 25) {
      setMinProcessor("i5");
      setMinRam("16 GB");
      setMinCamCount("25");
      setMinhardwarecost(75000);
      setMaxProcessor("i5");
      setMaxRam("32 GB");
      setMaxCamCount("30");
      setHardwarecost(75000);
    } else if (count < 30) {
      setMinProcessor("i5");
      setMinRam("32 GB");
      setMinCamCount("30");
      setMinhardwarecost(75000);
      setMaxProcessor("i7");
      setMaxRam("16 GB");
      setMaxCamCount("35");
      setHardwarecost(99999);
    } else if (count < 35) {
      setMinProcessor("i7");
      setMinRam("16 GB");
      setMinCamCount("35");
      setMinhardwarecost(99999);
      setMaxProcessor("i7");
      setMaxRam("32 GB");
      setMaxCamCount("42");
      setHardwarecost(99999);
    } else if (count < 42) {
      setMinProcessor("i7");
      setMinRam("32 GB");
      setMinCamCount("42");
      setMinhardwarecost(99999);
      setMaxProcessor("i7");
      setMaxRam("64 GB");
      setMaxCamCount("50");
      setHardwarecost(99999);
    } else {
      setMinProcessor("i7");
      setMinRam("32 GB");
      setMinCamCount("42");
      setMinhardwarecost(110000);
      setMaxProcessor("i7");
      setMaxRam("64 GB");
      setMaxCamCount("50");
      setHardwarecost(110000);
    }
  };

  return (
    <div className={Hard.maincontainer}>
      <img className={Hard.logoimage} src={Logo} alt="logo" />
      <div className={Hard.progressholder}>
        <ProgressBar
          percent={50}
          filledBackground="#C7DBFF"
          unfilledBackground="#E4E4E4"
          height={5}
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={IndustryBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={CameraCountBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={HardBlue}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={FeatureYellow}
                alt="stepone"
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width={60}
                src={QuoteYellow}
                alt="stepone"
              />
            )}
          </Step>
        </ProgressBar>
      </div>
      <h3 className={Hard.screenheading}>Select Hardware</h3>
      <div className={Hard.hardselectholder}>
        <div className={Hard.hardcard}>
          <div className={Hard.leftdetails}>
            <h3 className={Hard.smallhead}>Minimum:</h3>
            <h4 className={Hard.harddetails}>Processor: {minProcessor}</h4>
            <h4 className={Hard.harddetails}>RAM: {minRam}</h4>
            <h4 className={Hard.harddetails}>SSD: 128 GB</h4>
            <br />
            <h5 className={Hard.hardvalue}>
              (Recommended for less than {minCamCount} cameras)
            </h5>
          </div>
          <div className={Hard.rightdetails}>
            <input
              type="radio"
              className={Hard.inputradio}
              checked={minHardware}
              onChange={() => {
                setMinHardware(minHardware === true ? false : true);
                setRecHardware(false);
              }}
            />
          </div>
        </div>
        <div className={Hard.hardcardblue}>
          <div className={Hard.leftdetails}>
            <h3 className={Hard.smallhead}>Recommended:</h3>
            <h4 className={Hard.harddetails}>Processor: {maxProcessor}</h4>
            <h4 className={Hard.harddetails}>RAM: {maxRam}</h4>
            <h4 className={Hard.harddetails}>SSD: 128 GB</h4>
            <br />
            <h5 className={Hard.hardvalue}>
              (Recommended for less than {maxCamCount} cameras)
            </h5>
          </div>
          <div className={Hard.rightdetails}>
            <input
              type="radio"
              className={Hard.inputradio}
              checked={recHardware}
              onChange={() => {
                setRecHardware(recHardware === true ? false : true);
                setMinHardware(false);
              }}
            />
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          let userData = { ...userDetails };
          if (minHardware === true) {
            userData.hardwarecost = minHardwarecost;
          } else {
            userData.hardwarecost = hardwarecost;
          }
          dispatch(stateUpdate(userData));
          navigate("/selectfeatures");
        }}
        className={Hard.nextbutton}
      >
        Next
      </button>
    </div>
  );
};

export default Hardware;
